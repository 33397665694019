.modal-header {
  @extend .border-0;
  @extend .text-center;
  .close {
    @extend .position-absolute;
    @extend .p-0;
    right: 32px;
  }
}
  
.modal-footer {
  @extend .border-0;
  @extend .pt-0;
  justify-content: flex-start;
}
  
.modal-title {
  @extend .mx-auto;
}

.modal.fade.fade-down .modal-dialog {
  transform: translate(0, 50px);
}

.modal.fade.fade-down.show .modal-dialog {
  transform: none;
}
  
.modal-header.text-white {
  .close {
    color: inherit;
    opacity: 100;
  }
}

.loading-merchants-modal {
  position: fixed;
  z-index: 3000;
  bottom: 0px;
  opacity: 0;
  left: 50%;
  width: 320px;
  max-width: 100%;
  transform: translateX(-50%);
  transition: bottom ease-in 0.4s, opacity ease-in 0.4s;
  display: none;
  &.show {
    bottom: 100px;
    opacity: 1;
    display: block;
  }
}