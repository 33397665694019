.clickable {
  cursor: pointer;
}

//ToolTip
.tooltip.show {
  opacity: 1;
}

//Border
.border {
  border-color: color('grayLight')!important;
}
.border-top {
  border-top: solid 1px color('grayLight');
}
.border-bottom {
  border-bottom: solid 1px color('grayLight');
}

.company-logo {
  max-height: 65px;
  max-width: 190px;
}


//Scrollbar
/* width */
::-webkit-scrollbar {
  width: 6px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: color('grayDarker');
}
::-webkit-scrollbar-track {
  background: color('grayLighter');
}
::-webkit-scrollbar-track:hover {
  background: color('grayLight');
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: color('gray');
  border-radius: 5px;
}